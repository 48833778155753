import React from "react"
import { relatedLinkStyle } from '../style/relatedLinksStyle';
import { useStaticQuery, graphql, Link } from "gatsby"

var moment = require('moment');

const NewsSidebar = (props) => {

  const data = useStaticQuery(graphql`
    query SidebarNewsQuery {
      wpgraphql {
        newsAll(where: {orderby: {field: DATE, order: DESC}}, first: 20) {
          nodes {
            uri
            title
            id
            slug
            Newsfields {
              newsCategory
              newsDate
            }
          }
        }
      }
    }
  `);

  const allPages = []
  data.wpgraphql.newsAll.nodes.forEach(node => {
    if (node.Newsfields.newsDate!==null && node.Newsfields.newsDate!=="") {
      var dateshort = moment(node.Newsfields.newsDate).format('DD/MM/YYYY');
    
      if (props.Mode==="port" && node.Newsfields.newsCategory==="Port of Jersey" && allPages.length < 4)
        allPages.push({ "id": node.id, "title":'<strong>'+dateshort+'</strong> - '+node.title, "uri" : node.uri,  "slug" : node.slug, "newsCategory" : node.Newsfields.newsCategory, "newsDate" : node.Newsfields.newsDate, "dateFormatShort": dateshort})

      if (props.Mode==="coastguard" && node.Newsfields.newsCategory==="Jersey Coastguard" && allPages.length < 4)
        allPages.push({ "id": node.id, "title":'<strong>'+dateshort+'</strong> - '+node.title, "uri" : node.uri,  "slug" : node.slug, "newsCategory" : node.Newsfields.newsCategory, "newsDate" : node.Newsfields.newsDate, "dateFormatShort": dateshort})

      if (props.Mode==="marinas" && node.Newsfields.newsCategory==="Jersey Marinas" && allPages.length < 4)
        allPages.push({ "id": node.id, "title":'<strong>'+dateshort+'</strong> - '+node.title, "uri" : node.uri,  "slug" : node.slug, "newsCategory" : node.Newsfields.newsCategory, "newsDate" : node.Newsfields.newsDate, "dateFormatShort": dateshort})

    }
  });


  return (
      <div css={relatedLinkStyle} id="related" className="col-6-12">
        {props.Mode==="coastguard" && <h2 className="coastguard"><Link to="/news">Coastguard News</Link></h2> }
        {props.Mode==="port" && <h2 className="port"><Link to="/news">Port News</Link></h2> }
        {props.Mode==="marinas" && <h2 className="marinas"><Link to="/news">Marina News</Link></h2> }
        <ul className="post">
          {allPages.map(page => 
            <li key={page.id}><a href={"../"+page.uri} dangerouslySetInnerHTML={{ __html: page.title }} ></a></li>
          )}
        </ul>               
         {props.Advert.length > 0 &&  
               <React.Fragment>
                <div className="banner"><div dangerouslySetInnerHTML={{ __html: props.Advert[0].advertHTML }} /></div> 
                </React.Fragment>
              }

      </div>
  )
}
export default NewsSidebar
